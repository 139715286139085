import React, { useState } from 'react';
import { navigate } from 'gatsby';
import { nanoid } from 'nanoid';

import {
  Container,
  Box,
  Typography,
  AppBar,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  lighten,
  Button,
  Fab,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LoginIcon from '@mui/icons-material/Login';
import AssignmentIcon from '@mui/icons-material/Assignment';
import KeyIcon from '@mui/icons-material/Key';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { FullContainer } from '@components/layout';
import Logo from '@assets/newlogo.svg';

const CentralDeAjuda = () => {
  const [expanded, setExpanded] = useState('');
  const handleChange = (panel) => () => {
    setExpanded((old) => (old === panel ? '' : panel));
  };
  return (
    <FullContainer sx={{ flexDirection: 'column', justifyContent: 'flex-start' }}>
      <AppBar
        position="relative"
        sx={{
          p: '1rem',
          bgcolor: 'var(--gray-4)',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          color: '#fff',
        }}
      >
        <Box component={Logo} />
        <Typography variant="h4" sx={{ fontWeight: 'bold', mt: '2rem' }}>
          Central de ajuda
        </Typography>
      </AppBar>
      <Box sx={{ width: 1, height: 1, overflowY: 'overlay', bgcolor: lighten('#3D4458', 0.95) }}>
        <Container
          maxWidth="sm"
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            pt: '2rem',
            pb: '8rem',
          }}
        >
          {content.map((data, index) => (
            <AccordionItem
              key={data.title}
              data={data}
              expanded={expanded === `panel-${index}`}
              onChange={handleChange(`panel-${index}`)}
            />
          ))}
        </Container>
      </Box>
      <AppBar
        position="fixed"
        sx={{
          top: 'auto',
          bottom: 0,
          backgroundImage: 'linear-gradient(to bottom, #ffffff, #f7f7fc, #eeeff8, #e4e8f5, #d9e1f2)',
        }}
      >
        <Container
          maxWidth="sm"
          sx={{
            pb: '1.5rem',
            pt: '1.8rem',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            position: 'relative',
          }}
        >
          <Fab
            color="primary"
            sx={{ position: 'absolute', top: 0, transform: 'translateY(-50%)' }}
            onClick={() => {
              navigate(-1);
            }}
          >
            <ArrowBackIcon />
          </Fab>
          <Typography color="primary">Voltar</Typography>
        </Container>
      </AppBar>
    </FullContainer>
  );
};

const AccordionItem = ({ data: { Icon, title, content }, ...props }) => (
  <Accordion
    {...props}
    sx={{
      mb: '1.5rem',
      bgcolor: lighten('#3D4458', 0.95),
      '&.Mui-expanded': {
        mt: 0,
      },
    }}
  >
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      sx={{ bgcolor: 'var(--gray-1)', '& .MuiAccordionSummary-content': { alignItems: 'center', gap: '1rem' } }}
    >
      <Box
        sx={{
          width: '3rem',
          height: '3rem',
          minWidth: '3rem',
          borderRadius: '50%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundImage: 'linear-gradient(to right bottom, #9ba4b2, #828b9b, #6a7284, #535b6e, #3d4458)',
          color: '#fff',
          '& svg': {
            width: '2.5rem',
            height: '2.5rem',
          },
        }}
      >
        <Icon />
      </Box>
      <Typography variant="h6" sx={{ fontWeight: 'normal' }}>
        {title}
      </Typography>
    </AccordionSummary>
    <AccordionDetails
      sx={{
        '& p': {
          mt: '1.5rem',
        },
      }}
    >
      {content.map((text) => (
        <Typography key={nanoid()}>{text}</Typography>
      ))}
    </AccordionDetails>
  </Accordion>
);

const content = [
  {
    title: 'Problemas ao tentar acessar:',
    Icon: LoginIcon,
    content: [
      `Verifique seu email e sua senha se estão digitados corretamente e tente novamente, se mesmo assim não conseguir acessar o app, solicite "Recuperar minha Senha", insira seu email e envie.`,
      `No seu provedor de email verifique a caixa de entrada e se não ver o email para recuperar a senha, verifique também o spam.`,
      `Caso vosê não receba o e-mail de recuperacão da senha é possivel não ter digitado o email corretamente ou esse email não estar cadastrado em nosso sistema.`,
      `Se não conseguir seu acesso seguindo esses passos, entre em contato com nosso suporte, estamos aqui para ajudar você.`,
    ],
  },
  {
    title: 'Não consigo finalizar meu cadastro',
    Icon: AssignmentIcon,
    content: [
      `Para você conseguir finalizar seu cadastro é preciso preencher corretamente todas as informações.`,
      `Use um e-mail que você tenha acesso, seu cadastro mesmo que finalizado não te dará acesso antes que você verifique sua conta via o email utilizado.`,
      `Caso você já tenha feito o cadastro anteriormente e não excluiu sua conta, não será possivel criar um novo cadastro com o mesmo e-mail, você pode tentar recuperar a senha e se não conseguir entre em contato com nosso suporte, estamos aqui para ajudar você.`,
    ],
  },
  {
    title: 'Como recuperar minha senha?',
    Icon: KeyIcon,
    content: [
      `Caso tenha esquecido sua senha não se preocupe, é fácil resolver.`,
      `Clique em Esqueci a Senha na tela inicial do app, digite seu email cadastrado corretamente e clique em enviar, pronto.`,
      `Abra a caixa de entrada do seu e-mail e se não encontrar nosso e-mail de recuperação de senha, veja também sua caixa de spam.`,
      `Não recebeu o e-mail, entre em contato com o suporte e ajudamos você.`,
    ],
  },
  {
    title: 'Não achou ajuda aqui? Entre em contato conosco',
    Icon: WhatsAppIcon,
    content: [
      <Box component="span" sx={{ width: 1, display: 'flex', justifyContent: 'center' }}>
        <Button variant="contained" color="primary">
          Suporte <WhatsAppIcon sx={{ ml: '1rem' }} />
        </Button>
      </Box>,
    ],
  },
];

export default CentralDeAjuda;
